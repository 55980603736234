import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import { graphql } from "gatsby"
import SEO from "../components/Seo/seo"

function googleFormPages({ data, location }) {
  const { title, description, googleFormEmbed, image, slug } =
    data.strapiGoogleFormPage
  return (
    <Wrapper>
      <SEO
        title={title ? title : ""}
        description={description ? description : ""}
        location={`${location.pathname}`}
        image={image && image.localFile && image.localFile.url}
      />
      <Container>
        <div className="google_forms_wrapper">
          <ReactMarkdown
            children={googleFormEmbed}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
          />
        </div>

        <div className="image_wrapper">
          <h2>{title && title}</h2>
          <h4>{description && description}</h4>
          {image &&
            image.localFile &&
            (image.localFile.childImageSharp ? (
              <GatsbyImage
                className="image"
                image={image?.localFile?.childImageSharp?.gatsbyImageData}
                alt={title}
              />
            ) : (
              <img src={image?.localFile?.url} alt={title} />
            ))}
        </div>
      </Container>
    </Wrapper>
  )
}

export default googleFormPages

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  width: 100%;
  min-height: 700px;
  margin-top: 100px;
  margin-bottom: 50px;
`

const Container = styled.div`
  grid-area: 1/2/2/11;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  height: auto;

  .google_forms_wrapper {
    grid-area: 1/1/2/2;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    height: 750px;
    overflow-y: auto;
    @media (max-width: 991px) {
      grid-area: 2/1/3/3;
      margin-right: 0;
      iframe {
        width: 100%;
      }
    }
  }
  .image_wrapper {
    grid-area: 1/2/3/3;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    h2 {
      font-weight: 700;
    }
    h4 {
      margin-top: 0;
      font-size: 14px;
      font-weight: 500;
    }
    .gatsby-image-wrapper {
      width: 100%;
      height: auto;
      border-radius: 6px;
      @media (max-width: 991px) {
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width: 991px) {
      grid-area: 1/1/2/3;
      padding: 20px 0;
      margin-left: 0;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: contain !important;
      @media (max-width: 991px) {
        width: 100%;
        height: 100%;
      }
    }
  }
`
export const query = graphql`
  query googleFormPages($slug: String) {
    strapiGoogleFormPage(slug: { eq: $slug }) {
      slug
      title
      googleFormEmbed
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              layout: FULL_WIDTH
              formats: [AUTO, WEBP, AVIF]
            )
          }
          url
        }
      }
    }
  }
`
